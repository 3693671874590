import type { ButtonProps as ChakraButtonProps } from "@chakra-ui/react";
import {
  AbsoluteCenter,
  Button as ChakraButton,
  Span,
  Spinner,
} from "@chakra-ui/react";
import * as React from "react";

interface ButtonLoadingProps {
  loading?: boolean;
  loadingText?: React.ReactNode;
  opaque?: boolean;
}

export interface ButtonProps extends ChakraButtonProps, ButtonLoadingProps {}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props, ref) {
    const {
      loading,
      disabled,
      loadingText,
      children,
      opaque = true,
      ...rest
    } = props;
    return (
      <ChakraButton
        disabled={loading || disabled}
        ref={ref}
        {...rest}
        bg={opaque ? "#003366" : "white"}
        color={opaque ? "white" : "#101828"}
        _hover={{
          bg: opaque ? "#00152B" : "gray.200",
          transform: "scale(1.05)", // Slightly enlarge the button
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
        }}
        _active={{
          transform: "scale(1)", // Reset scale on click
        }}
        transition="all 0.2s ease-in-out" // Smooth transition
        borderRadius={"10px"}
        fontSize={"16px"}
        fontFamily={"Outfit-SemiBold"}
        borderColor={"#D0D5DD"}
        h="48px"
        _disabled={{ bg: "#D0D5DD", color: "#667085" }}
      >
        {loading && !loadingText ? (
          <>
            <AbsoluteCenter display="inline-flex">
              <Spinner size="inherit" color="inherit" />
            </AbsoluteCenter>
            <Span opacity={0}>{children}</Span>
          </>
        ) : loading && loadingText ? (
          <>
            <Spinner size="inherit" color="inherit" />
            {loadingText}
          </>
        ) : (
          children
        )}
      </ChakraButton>
    );
  }
);
