import { FC, useState, useEffect } from "react";
import { Flex, Textarea } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";

import { Field } from "../components/ui/field";
import { Button } from "../components/ui/button";
import { CustomSelect } from "../components/CustomSelect";

interface CarDetailsProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
}

interface FormValues {
  make: string;
  model: string;
  carChallengess: string;
  pastExperience: string;
  experience?: string;
}

const challenges = [
  {
    label: "Difficulty finding reliable mechanics",
    value: "Difficulty finding reliable mechanics",
  },
  { label: "High repair costs", value: "High repair costs" },
  {
    label: "Long wait times for repairs",
    value: "Long wait times for repairs",
  },
  {
    label: "Limited access to mechanics nearby",
    value: "Limited access to mechanics nearby",
  },
  {
    label: "Lack of transparency in pricing and repairs",
    value: "Lack of transparency in pricing and repairs",
  },
];

const experiences = [
  { label: "Positive", value: "Positive" },
  { label: "Negative", value: "Negative" },
  { label: "Specific", value: "Specific" },
];

export const CarDetails: FC<CarDetailsProps> = ({ setOpen, id }) => {
  const navigate = useNavigate();

  const [makes, setMakes] = useState<any[]>([]);
  const [models, setModels] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [isFetchingMakes, setIsFetchingMakes] = useState<boolean>(false);
  const [isFetchingModels, setIsFetchingModels] = useState<boolean>(false);

  const fetchCarRecords = async () => {
    try {
      setIsFetchingMakes(true);
      const _makes = await axios.get(
        `${process.env.REACT_APP_API_URL}/car/records?group_by=make`
      );

      setMakes(
        _makes.data.data.results.map((car: { make: string }) => {
          return {
            label: car.make,
            value: car.make,
          };
        })
      );

      setIsFetchingMakes(false);
    } catch (error) {
      setIsFetchingMakes(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCarRecords();
  }, []);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<FormValues>({ mode: "onChange" });

  // Watch for changes to make
  const make = watch("make");
  const pastExperience = watch("pastExperience");

  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoading(true);

      const body = { id, ...data };

      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/pre-register`,
        body
      );

      if (response.data.success) {
        setOpen(true);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  });

  useEffect(() => {
    const getModels = async () => {
      setIsFetchingModels(true);

      const _models = await axios.get(
        `${process.env.REACT_APP_API_URL}/car/records?group_by=make, model&refine=make:${make}`
      );

      setModels(
        _models.data.data.results.map((car: { model: string }) => {
          return {
            label: car.model,
            value: car.model,
          };
        })
      );

      setIsFetchingModels(false);
    };

    make && getModels();
  }, [make]);

  return (
    <form onSubmit={onSubmit}>
      <CustomSelect
        options={makes}
        label="Car Make"
        control={control}
        name="make"
        isLoading={isFetchingMakes}
      />

      <CustomSelect
        options={models}
        label="Car Model"
        control={control}
        name="model"
        isLoading={isFetchingModels}
      />

      <CustomSelect
        options={challenges}
        label="Car Challenges"
        helperText="What is your biggest challenge as a car owner?"
        control={control}
        name="carChallenges"
      />

      <CustomSelect
        options={experiences}
        label="Past experience with mechanics"
        helperText="How has your experience with mechanics been in the past?"
        control={control}
        name="pastExperience"
      />

      <Field
        label="Experience"
        mt="24px"
        invalid={!!errors.experience}
        errorText={errors.experience?.message}
        required={pastExperience === "Specific"}
      >
        <Textarea
          {...register("experience")}
          placeholder="Describe your biggest challenge"
          variant="outline"
          borderColor={"#D0D5DD"}
          borderRadius={0}
          resize={"vertical"}
          rows={4}
          _hover={{
            borderColor: "#003366",
            transform: "scale(1.03)", // Slightly enlarge the button
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
          }}
          _focus={{
            borderColor: "#003366",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
          }}
          transition="all 0.2s ease-in-out" // Smooth transition
        />
      </Field>

      <Flex gap={"15px"} mt="32px">
        <Button
          flex={1}
          onClick={() => navigate(-1)}
          display={{ base: "flex", md: "none" }}
          opaque={false}
        >
          Previous
        </Button>

        <Button
          flex={1}
          type="submit"
          disabled={!isValid ? true : loading}
          loading={loading}
        >
          Submit
        </Button>
      </Flex>
    </form>
  );
};
