import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AccountType, Details } from "./pages";

export const WebRoutes = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route index path="/" element={<AccountType />} />
          <Route index path="/details" element={<Details />} />
        </Routes>
      </Router>
    </>
  );
};
