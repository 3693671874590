import React from "react";
import Select from "react-select";
import { Box, Text } from "@chakra-ui/react";
import { Controller } from "react-hook-form";

export type ValueType = { value: string; label: string } | null;

interface CustomSelectProps {
  options: { value: string; label: string }[];
  label?: string;
  helperText?: string;
  control: any; // react-hook-form's control object
  name: string; // Field name for react-hook-form
  rules?: object;
  isMulti?: boolean; // Allow multiple selections
  isLoading?: boolean; // Allow multiple selections
  placeholder?: string; // Allow multiple selections
}

export const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  label,
  helperText,
  control,
  name,
  rules,
  isMulti = false,
  isLoading = false,
  placeholder = "",
}) => {
  const calculatedWidth = `calc(100vw - 48px)`;

  return (
    <Box mt={label ? "24px" : 0}>
      {label && (
        <Text
          fontSize={"14px"}
          color={"#101828"}
          fontFamily={"Outfit-Regular"}
          mb="6px"
        >
          {label}
        </Text>
      )}

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <Select
            {...field}
            isLoading={isLoading}
            // placeholder={placeholder}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                minHeight: "40px",
                fontSize: "14px",
                fontFamily: "Outfit-Regular",
                borderRadius: 0,
                borderColor: state.isFocused ? "#003366" : "#D0D5DD", // Focused border color
                boxShadow: state.isFocused ? "0 0 0 1px #003366" : "none", // Outline on focus
                "&:hover": {
                  borderColor: "#003366",
                  transform: "scale(1.03)", // Slightly enlarge the button
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
                },
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                color: state.isSelected ? "white" : "#101828", // Text color
                fontFamily: "Outfit-Regular",
                backgroundColor: state.isSelected
                  ? "#003366" // Active option background
                  : state.isFocused
                  ? "#EDF2F7" // Hover background color
                  : "white", // Default background color
                "&:active": {
                  backgroundColor: "#003366", // Active click background color
                  color: "white",
                },
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                width: window.matchMedia("(min-width: 458px)").matches
                  ? "410px" // Width for md and above
                  : calculatedWidth, // Width for smaller screens
              }),
              multiValue: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: "#F2F4F7", // Background color for selected options
                borderRadius: "8px",
                padding: "2px 10px",
              }),
              multiValueLabel: (baseStyles) => ({
                ...baseStyles,
                color: "#101828", // Text color for selected options
                fontSize: "14px",
                fontFamily: "Outfit-Regular",
              }),
              multiValueRemove: (baseStyles) => ({
                ...baseStyles,
                color: "#101828", // Default color of the cancel icon
                cursor: "pointer",
                "&:hover": {
                  color: "red", // Color when hovering over the cancel icon
                  backgroundColor: "transparent", // Remove hover background
                },
              }),
            }}
            isMulti={isMulti}
            options={options}
            value={
              isMulti
                ? options.filter((option) =>
                    field.value?.includes(option.value)
                  )
                : options.find((option) => option.value === field.value)
            }
            onChange={(selectedOptions) =>
              field.onChange(
                isMulti
                  ? (selectedOptions as unknown as { value: string }[]).map(
                      (option) => option.value
                    )
                  : (selectedOptions as { value: string })?.value
              )
            }
          />
        )}
      />

      {helperText && (
        <Text
          fontSize={"15px"}
          color={"#667085"}
          fontFamily={"Outfit-Regular"}
          mt="6px"
        >
          {helperText}
        </Text>
      )}
    </Box>
  );
};
