import { useState } from "react";
import { Box, Heading, Text, Flex, Icon } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

import { PersonalDetails } from "../features/PersonalDetails";
import { CarDetails } from "../features/CarDetails";
import { BusinessDetails } from "../features/BusinessDetails";
import { Button } from "../components/ui/button";
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogRoot,
} from "../components/ui/dialog";
import { Header } from "../components/Header";

export const Details = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const searchParams = new URLSearchParams(location.search);

  const userType = searchParams.get("userType");
  const id = searchParams.get("id");

  return (
    <Box bg="white" h="100vh" overflowY={"scroll"} pb="100px">
      <Header />

      <Box
        mt={{ base: "21px", md: "80px" }}
        maxW={"458px"}
        justifySelf={"center"}
        px="24px"
      >
        <Heading
          fontSize={{ base: "28px", md: "34px" }}
          fontWeight={600}
          color={"#1E1C24"}
          textAlign={{ base: "left", md: "center" }}
          mb="8px"
          fontFamily={"Outfit-SemiBold"}
        >
          Let's get started
        </Heading>

        <Text
          fontSize={"17px"}
          color={"#667085"}
          textAlign={{ base: "left", md: "center" }}
          fontFamily={"Outfit-Regular"}
        >
          Complete your details to become an early Aucarga tester.
        </Text>

        <Box>
          <Flex gap={"8px"} align={"center"} my={{ base: "24px", md: "39px" }}>
            <Flex borderRadius={"40px"} bg="#ECEEF2" h="4px" flex={1}>
              <Box bg="#003366" w={id ? "100%" : "50%"} borderRadius={"40px"} />
            </Flex>

            <Text
              fontSize={"13px"}
              color={"#23272E"}
              fontFamily={"Outfit-Regular"}
            >
              {id ? "2" : "1"}/2
            </Text>
          </Flex>

          {id ? (
            userType === "car_owner" ? (
              <CarDetails setOpen={setOpen} id={id} />
            ) : (
              <BusinessDetails setOpen={setOpen} id={id} />
            )
          ) : (
            <PersonalDetails userType={userType} />
          )}
        </Box>
      </Box>

      <DialogRoot
        placement={"center"}
        motionPreset="slide-in-bottom"
        lazyMount
        open={open}
        onOpenChange={(e) => setOpen(e.open)}
        onExitComplete={() => navigate("/")}
        size={"xs"}
      >
        <DialogContent>
          <DialogBody pt="24px">
            <Flex
              justifySelf={"center"}
              alignSelf={"center"}
              alignContent={"center"}
              justifyContent={"center"}
              w="100%"
              mb="20px"
            >
              <Box
                borderWidth={"8px"}
                borderColor={"#ECFDF3"}
                h={"40px"}
                w="40px"
                borderRadius={"full"}
              >
                <Flex
                  bg="#D1FADF"
                  w="100%"
                  h="100%"
                  align={"center"}
                  justify={"center"}
                  borderRadius={"full"}
                >
                  <Icon fontSize={"20px"} color={"#039855"}>
                    <IoMdCheckmarkCircleOutline />
                  </Icon>
                </Flex>
              </Box>
            </Flex>

            <Text
              fontSize={"20px"}
              fontWeight={700}
              textAlign={"center"}
              color={"#101828"}
              mb="8px"
              fontFamily={"Outfit-Regular"}
            >
              🎉 Thank You
            </Text>

            <Text
              fontSize={"16px"}
              color={"#667085"}
              textAlign={"center"}
              fontFamily={"Outfit-Regular"}
            >
              Your responses have been submitted successfully. Further steps
              will be sent to your email. Stay tuned, we're excited to have you
              on board!
            </Text>
          </DialogBody>

          <DialogFooter>
            <Button w="100%" onClick={() => navigate("/")}>
              Back to Website
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </Box>
  );
};
