import { FC, useState } from "react";
import { Box, Flex, Input, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Country } from "country-state-city";

import { CustomSelect } from "../components/CustomSelect";
import { Field } from "../components/ui/field";
import { Button } from "../components/ui/button";

interface PersonalDetailsProps {
  userType: string | null;
}

interface FormValues {
  firstName: string;
  lastName: string;
  phone: string;
  countryCode: string;
  email: string;
}

export const PersonalDetails: FC<PersonalDetailsProps> = ({ userType }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<FormValues>({ mode: "onChange" });

  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoading(true);

      const { countryCode, ...rest } = data;

      const countryCodeSplit = countryCode.split(" ")[1];
      const body = { ...rest, countryCode: countryCodeSplit, userType };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/pre-register`,
        body
      );

      if (response.data.success) {
        navigate(`/details?userType=${userType}&id=${response.data.data._id}`);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  });

  const allCountries = Country.getAllCountries();

  const countries = allCountries.map((country) => {
    return {
      label: `${country.flag} ${country.phonecode.startsWith("+") ? "" : "+"}${
        country.phonecode
      } (${country.name})`,

      value: `${country.flag} ${country.phonecode.startsWith("+") ? "" : "+"}${
        country.phonecode
      }`,
    };
  });

  return (
    <form onSubmit={onSubmit}>
      <Flex gap="18px" width="full" flexDir={{ base: "column", md: "row" }}>
        <Field
          label="First Name"
          invalid={!!errors.firstName}
          errorText={errors.firstName?.message}
        >
          <Input
            {...register("firstName", { required: "First name is required" })}
            placeholder="Enter first name"
            variant="outline"
            borderColor={"#D0D5DD"}
            borderRadius={0}
            _hover={{
              borderColor: "#003366",
              transform: "scale(1.03)", // Slightly enlarge the button
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
            }}
            _focus={{
              borderColor: "#003366",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
            }}
            transition="all 0.2s ease-in-out" // Smooth transition
          />
        </Field>

        <Field
          label="Last Name"
          invalid={!!errors.lastName}
          errorText={errors.lastName?.message}
        >
          <Input
            {...register("lastName", { required: "Last name is required" })}
            placeholder="Enter last name"
            variant="outline"
            borderColor={"#D0D5DD"}
            borderRadius={0}
            _hover={{
              borderColor: "#003366",
              transform: "scale(1.03)", // Slightly enlarge the button
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
            }}
            _focus={{
              borderColor: "#003366",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
            }}
            transition="all 0.2s ease-in-out" // Smooth transition
          />
        </Field>
      </Flex>

      <Field
        label="Email"
        mt="24px"
        invalid={!!errors.email}
        errorText={errors.email?.message}
      >
        <Input
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Regex for email format
              message: "Enter a valid email address",
            },
          })}
          placeholder="Enter email address"
          variant="outline"
          borderColor={"#D0D5DD"}
          borderRadius={0}
          _hover={{
            borderColor: "#003366",
            transform: "scale(1.03)", // Slightly enlarge the button
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
          }}
          _focus={{
            borderColor: "#003366",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
          }}
          transition="all 0.2s ease-in-out" // Smooth transition
        />
      </Field>

      <Box mt="24px">
        <Text fontSize={"14px"} color={"#101828"} fontFamily={"Outfit-Regular"}>
          Phone number
        </Text>

        <Flex gap={"8px"} mt="6px">
          <CustomSelect
            options={countries}
            control={control}
            name="countryCode"
          />

          <Field invalid={!!errors.phone} errorText={errors.phone?.message}>
            <Input
              {...register("phone", {
                required: "Phone number is required",
                pattern: {
                  value: /^[0-9]*$/, // Allows only numeric values
                  message: "Phone number must contain only numbers",
                },
                minLength: {
                  value: 6,
                  message: "Phone number must be at least 6 digits long",
                },
                maxLength: {
                  value: 12,
                  message: "Phone number cannot exceed 12 digits",
                },
              })}
              placeholder="063 656 9812"
              variant="outline"
              borderColor={"#D0D5DD"}
              borderRadius={0}
              _hover={{
                borderColor: "#003366",
                transform: "scale(1.03)", // Slightly enlarge the button
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
              }}
              _focus={{
                borderColor: "#003366",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
              }}
              transition="all 0.2s ease-in-out" // Smooth transition
            />
          </Field>
        </Flex>
      </Box>

      <Flex gap={"15px"} mt="32px">
        <Button
          flex={1}
          onClick={() => navigate(-1)}
          display={{ base: "flex", md: "none" }}
          opaque={false}
        >
          Previous
        </Button>

        <Button
          flex={1}
          type="submit"
          loading={loading}
          disabled={!isValid ? true : loading}
        >
          Continue
        </Button>
      </Flex>
    </form>
  );
};
