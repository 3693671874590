import { Provider } from "./components/ui/provider";
import { WebRoutes } from "./routes";
import "./App.css";

function App() {
  return (
    <Provider>
      <WebRoutes />
    </Provider>
  );
}

export default App;
