import { FC } from "react";
import { Image, Flex, Icon, Box } from "@chakra-ui/react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import Logo from "../assets/images/logo.png";

export const Header: FC = () => {
  const navigate = useNavigate();

  return (
    <Flex
      px={{ base: "15px", md: "80px" }}
      py="24px"
      borderBottomWidth={1}
      borderColor={"#ECEEF2"}
      align={"center"}
      justify={"space-between"}
    >
      <Flex
        w={"36px"}
        h="36px"
        borderRadius={"full"}
        borderWidth={"1px"}
        borderColor={"#D5D9E2"}
        align={"center"}
        justify={"center"}
        onClick={() => navigate(-1)}
        display={{ base: "none", md: "flex" }}
      >
        <Icon fontSize={"16px"} color={"#23272E"}>
          <IoArrowBackOutline />
        </Icon>
      </Flex>

      <Image
        src={Logo}
        h={{ base: "23px", md: "32px" }}
        w={{ base: "142px", md: "219px" }}
        objectFit={"contain"}
      />

      {/* <Button
        display={{ base: "none", md: "block" }}
        bg="#003366"
        _hover={{
          bg: "#00152B",
          transform: "scale(1.05)", // Slightly enlarge the button
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
        }}
        _active={{
          transform: "scale(1)", // Reset scale on click
        }}
        transition="all 0.2s ease-in-out" // Smooth transition
        color={"white"}
        borderRadius={0}
        fontSize={"16px"}
        fontWeight={500}
        fontFamily={"Outfit-SemiBold"}
      >
        Download App
      </Button> */}
      <Box w="36px" />
    </Flex>
  );
};
