import { useState } from "react";
import { Box, Heading, Text, Flex, Icon } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa6";

import { Button } from "../components/ui/button";
import { Header } from "../components/Header";

const accountTypes = [
  {
    id: 1,
    name: "Car Owner",
    value: "car_owner",
    description:
      "Find trusted mechanics nearby and book repairs quickly to get back on the road",
  },
  {
    id: 2,
    name: "Garage Owner",
    value: "garage_owner",
    description:
      "Grow your business with instant job requests and customer reviews, all in one platform.",
  },
];

export const AccountType = () => {
  const navigate = useNavigate();

  const [selectedAccountType, setSelectedAccountType] = useState<string>("");

  return (
    <Box bg="white" h="100vh">
      <Header />

      <Box
        mt={{ base: "21px", md: "80px" }}
        maxW={"458px"}
        justifySelf={"center"}
        px="24px"
      >
        <Heading
          fontSize={{ base: "28px", md: "34px" }}
          color={"#1E1C24"}
          textAlign={{ base: "left", md: "center" }}
          mb="8px"
          fontFamily={"Outfit-SemiBold"}
          _hover={{
            transform: "scale(1.05)", // Slightly enlarge the button
            textShadow: "0px 6px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow
          }}
          transition="all 0.2s ease-in-out" // Smooth transition
        >
          Signup as a
        </Heading>

        <Text
          fontSize={"17px"}
          color={"#667085"}
          textAlign={{ base: "left", md: "center" }}
          fontFamily={"Outfit-Regular"}
        >
          Signup to be among the first to test the Aucarga app
        </Text>

        <Box mt="32px">
          {accountTypes.map((type) => (
            <Flex
              mb="24px"
              key={type.id}
              borderWidth={"1px"}
              borderColor={
                selectedAccountType === type.value ? "#003366" : "#D0D5DD"
              }
              _hover={{
                borderColor: "#00152B",
                transform: "scale(1.05)", // Slightly enlarge the button
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
              }}
              _active={{
                transform: "scale(1)", // Reset scale on click
              }}
              transition="all 0.2s ease-in-out" // Smooth transition
              p={"16px"}
              borderRadius={"10px"}
              align={"center"}
              justify={"space-between"}
              gap={"32px"}
              onClick={() => setSelectedAccountType(type.value)}
              cursor={"pointer"}
            >
              <Box>
                <Text
                  fontSize={"16px"}
                  fontWeight={600}
                  color={"#091C35"}
                  fontFamily={"Outfit-SemiBold"}
                >
                  {type.name}
                </Text>
                <Text
                  fontSize={"13px"}
                  color={"#667085"}
                  fontFamily={"Outfit-Regular"}
                >
                  {type.description}
                </Text>
              </Box>

              <Icon fontSize={"20px"} color={"#344054"}>
                <FaAngleRight />
              </Icon>
            </Flex>
          ))}
        </Box>

        <Button
          w={"100%"}
          disabled={!selectedAccountType}
          onClick={() => navigate(`/details?userType=${selectedAccountType}`)}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};
