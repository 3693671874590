import { FC, useState, useEffect } from "react";
import { Flex, Input, Textarea } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Country } from "country-state-city";
import { useForm } from "react-hook-form";
import axios from "axios";

import { Field } from "../components/ui/field";
import { Button } from "../components/ui/button";
import { CustomSelect } from "../components/CustomSelect";

interface BusinessDetailsProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
}

interface FormValues {
  businessName: string;
  country: string;
  industrySpecialization: string[];
  staffCount: number;
  businessYears: number;
  experience: string;
}

export const BusinessDetails: FC<BusinessDetailsProps> = ({ setOpen, id }) => {
  const navigate = useNavigate();

  const [services, setServices] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const getServices = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/service`);

      setServices(
        res.data.data.map((service: { name: string }) => {
          return {
            label: service.name,
            value: service.name,
          };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<FormValues>({ mode: "onChange" });

  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoading(true);

      const body = { id, ...data };

      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/pre-register`,
        body
      );

      if (response.data.success) {
        setOpen(true);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  });

  const allCountries = Country.getAllCountries();

  const countries = allCountries.map((country) => {
    return {
      label: `${country.flag} ${country.name}`,
      value: `${country.name}`,
    };
  });

  return (
    <form onSubmit={onSubmit}>
      <Field
        label="Legal Business name"
        invalid={!!errors.businessName}
        errorText={errors.businessName?.message}
        mt="24px"
      >
        <Input
          {...register("businessName", {
            required: "Business name is required",
          })}
          placeholder="Enter legal business name"
          variant="outline"
          borderColor={"#D0D5DD"}
          borderRadius={0}
          _hover={{
            borderColor: "#003366",
            transform: "scale(1.03)", // Slightly enlarge the button
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
          }}
          _focus={{
            borderColor: "#003366",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
          }}
          transition="all 0.2s ease-in-out" // Smooth transition
        />
      </Field>

      <CustomSelect
        options={countries}
        label="Country of registration?"
        control={control}
        name="country"
      />

      <CustomSelect
        options={services}
        label="Area of Specialization"
        helperText="What type of services does your garage specialize in?"
        control={control}
        name="industrySpecialization"
        isMulti
      />

      <Field
        label="Staff Strength"
        invalid={!!errors.staffCount}
        errorText={errors.staffCount?.message}
        mt="24px"
        helperText="How many staff members work in your garage?"
      >
        <Input
          {...register("staffCount", {
            required: "Staff Strength is required",
          })}
          placeholder="Enter staff number"
          variant="outline"
          borderColor={"#D0D5DD"}
          borderRadius={0}
          _hover={{
            borderColor: "#003366",
            transform: "scale(1.03)", // Slightly enlarge the button
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
          }}
          _focus={{
            borderColor: "#003366",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
          }}
          transition="all 0.2s ease-in-out" // Smooth transition
        />
      </Field>

      <Field
        label="Active Business Years"
        invalid={!!errors.businessYears}
        errorText={errors.businessYears?.message}
        mt="24px"
        helperText="How many years have you been in business?"
      >
        <Input
          {...register("businessYears", {
            required: "Business Years is required",
          })}
          placeholder="e.g 1"
          variant="outline"
          borderColor={"#D0D5DD"}
          borderRadius={0}
          _hover={{
            borderColor: "#003366",
            transform: "scale(1.03)", // Slightly enlarge the button
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
          }}
          _focus={{
            borderColor: "#003366",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
          }}
          transition="all 0.2s ease-in-out" // Smooth transition
        />
      </Field>

      <Field
        label="Experience"
        invalid={!!errors.experience}
        errorText={errors.experience?.message}
        mt="24px"
        helperText="What has been your biggest challenge in running your garage ?"
      >
        <Textarea
          {...register("experience", {
            required: "Experience is required",
          })}
          placeholder="Describe your biggest challenge"
          variant="outline"
          borderColor={"#D0D5DD"}
          borderRadius={0}
          resize={"vertical"}
          rows={4}
          _hover={{
            borderColor: "#003366",
            transform: "scale(1.03)", // Slightly enlarge the button
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
          }}
          _focus={{
            borderColor: "#003366",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
          }}
          transition="all 0.2s ease-in-out" // Smooth transition
        />
      </Field>

      <Flex gap={"15px"} mt="32px">
        <Button
          flex={1}
          onClick={() => navigate(-1)}
          display={{ base: "flex", md: "none" }}
          opaque={false}
        >
          Previous
        </Button>

        <Button
          flex={1}
          type="submit"
          disabled={!isValid ? true : loading}
          loading={loading}
        >
          Submit
        </Button>
      </Flex>
    </form>
  );
};
